// Add your custom JS here.
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'; // Import the Swiper CSS

// Import Swiper core and modules
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// Register GSAP ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function () {

    // Navbar scroll behavior
    let lastScroll = 0;
    const navbar = document.getElementById('wrapper-navbar');
    const delta = 5;
    const navbarHeight = navbar?.offsetHeight || 0;

    window.addEventListener('scroll', function() {
        if (!navbar) return;

        const currentScroll = window.pageYOffset;
        
        // Make sure they scroll more than delta
        if (Math.abs(lastScroll - currentScroll) <= delta) {
            return;
        }
        
        // If they scrolled down and are past the navbar, add class .nav-up
        if (currentScroll > lastScroll && currentScroll > navbarHeight) {
            // Scrolling down
            navbar.classList.add('nav-up');
        } else {
            // Scrolling up
            if (currentScroll + window.innerHeight < document.documentElement.scrollHeight) {
                navbar.classList.remove('nav-up');
            }
        }
        
        lastScroll = currentScroll;
    }, { passive: true }); // Add passive flag for better scroll performance

    // Initialize Swiper with Autoplay
    const swiper = new Swiper('.swiper-container', {
        loop: true,  // Enable looping
        spaceBetween: 10,  // Space between slides
        slidesPerView: 1,  // Default for mobile
        observer: true,  // Enable dynamic updates
        observeParents: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        autoplay: {
            delay: 3000,  // Slide transition delay in milliseconds
            disableOnInteraction: false,  // Keep autoplay running after interaction
        },
        breakpoints: {
            576: { // For devices with a max-width of 576px
                slidesPerView: 1,
                spaceBetween: 10,
            },
            768: { // For devices with a max-width of 768px
                slidesPerView: 2,
                spaceBetween: 15,
            }
        },
        on: {
            init: function () {
                setTimeout(() => {
                    this.update(); // Force update after initialization
                }, 100);
            }
        }
    });

    // Basic GSAP configuration for smooth animation
    gsap.config({ nullTargetWarn: false }); // Suppresses warnings for null targets

    

    // GSAP animations for .hero section
    gsap.from('.hero-content h2', {
        scrollTrigger: {
            trigger: '.hero',
            start: 'top 80%',
            toggleActions: 'play none none none',
            once: true // Only trigger once for better performance
        },
        opacity: 0,
        y: 50,
        duration: 1,
        ease: 'power2.out'
    });

    gsap.from('.hero-content .lead', {
        scrollTrigger: {
            trigger: '.hero',
            start: 'top 75%',
            toggleActions: 'play none none none',
            once: true
        },
        opacity: 0,
        y: 50,
        duration: 1,
        delay: 0.5,
        ease: 'power2.out'
    });

    gsap.from('.hero-social a', {
        scrollTrigger: {
            trigger: '.hero',
            start: 'top 70%',
            toggleActions: 'play none none none',
            once: true
        },
        opacity: 0,
        y: 30,
        duration: 0.8,
        stagger: 0.2,
        ease: 'power2.out'
    });

    gsap.from('.hero .image img', {
        scrollTrigger: {
            trigger: '.hero',
            start: 'top 80%',
            toggleActions: 'play none none none',
            once: true
        },
        opacity: 0,
        scale: 0.9,
        duration: 1.5,
        ease: 'power2.out'
    });

    // GSAP animation for case studies
    document.querySelectorAll('.case-study').forEach(caseStudy => {
        gsap.from(caseStudy.querySelectorAll('.case-content > *, .case-content a'), {
            scrollTrigger: {
                trigger: caseStudy,
                start: 'top 80%',
                toggleActions: 'play none none none',
                once: true
            },
            opacity: 0,
            y: 50,
            duration: 1,
            stagger: 0.2,
            ease: 'power2.out',
            clearProps: 'all'
        });
    });
});
